<template>
    <div class='head-box'>
        <div class='logo-box'>
            <div class='logo'><a href=''>{{logoname}}</a></div>
            <ul class='header-bar-ul'>
                <li><a title='021-69704853'>客服电话</a></li>
                <li><a @click="addFavorite">加入收藏</a></li>
                <li><a href='/accountCenter'>个人中心</a></li>
                <li v-if="ifAdmin"><a href='/manage/userManage'>后台管理</a></li>
                <!-- <li><a href='/analysis'>数据展示</a></li> -->
                <li><a href='/index'>主页</a></li>
                <li v-if='logined'><span class='l-pad'>
                        <Icon type="ios-contact" /></span><span class='l-pad'>已登录</span><span class='l-pad' @click='logout'>退出</span></li>
                <li v-else>您好，<a href='/login'>请登录</a><a href='/register' class='l-pad'>注册</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
let authority = [];
let authData = JSON.parse(localStorage.getItem("authData"));
if (authData) {
  authority = authData.barRights;
}
    export default {
        name: 'headerBar',
        data() {
            return {
                logined: false,
                account: '12345678956',
            }
        },
        props: {
            logoname: {
                type: String,
                default: ""
            },
        },
        created() {
            let userToken = localStorage.getItem("current_user_token");
            if (userToken !== undefined && userToken !== null) {
                this.logined = true;
            }
        },
        computed:{
            ifAdmin:function(){
                return authority.includes('userManage')||authority.includes('super_all');
            }
        },
        methods: {
            addFavorite: function () {
                let url = window.location;
                let title = document.title;
                let ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf("360se") > -1) {
                    alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
                }
                else if (ua.indexOf("msie 8") > -1) {
                    window.external.AddToFavoritesBar(url, title); //IE8
                }
                else if (document.all) {
                    try {
                        window.external.addFavorite(url, title);

                    } catch (e) {
                        alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
                    }
                } else if (window.sidebar) {
                    window.sidebar.addPanel(title, url, "");
                } else {
                    alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
                }
            },
            logout: function () {
                localStorage.clear();
                this.logined = false;
                location.href = "/login";
            }
        }
    }
</script>

<style scoped>
    body {
        padding: 0;
        margin: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    span {
        cursor: pointer;
    }

    .head-box {
        width: 100%;
        height: 38px;
        background: rgb(34, 34, 54);
        color: rgb(176, 176, 176);
        min-width: 1280px;
    }

    .logo-box {
        width: 1280px;
        margin: 0 auto;
    }

    .logo {
        float: left;
        font-size: 12px;
        line-height: 36px;
        float: left;
    }

    .header-bar-ul {
        float: right;
        width: auto;
        height: 36px;
        line-height: 36px;
        margin: 0;
        padding: 0;
    }

    .header-bar-ul>li {
        margin-top: 12px;
        float: right;
        line-height: 12px;
        font-size: 12px;
        list-style: none;
        padding: 0 1em;
        height: 12px;
        border-right: solid 1px rgb(176, 176, 176);
    }

    .header-bar-ul>li:hover a {
        color: #eee;
    }

    li:first-child {
        border-right: none;
    }

    .l-pad {
        padding-left: 1em;
    }

    .high-light {
        color: #ffffff;
    }
</style>